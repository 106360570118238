<template>
  <div class="w-full h-full pt-2">
    <div class="flex">
      <div class="pr-5">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">Employees</h1>
      </div>
      <div class="w-7/12 mt-4">
        <Breadcrumb
          @onClick="handleBreadcrumbClick($event)"
          :items="breadcrumbs"
        />
      </div>
    </div>
    <div class="mx-3 px-5 mt-5 mb-4 pb-10">
      <div class="flex">
        <Sidebar
          :current-tab="currentTab"
          :tabs="
            $store.state.subscription &&
            $store.state.subscription.plan === 'basic'
              ? basicTab
              : tabs
          "
          @currentTab="handleClick($event)"
        />
        <PersonalInfo
          v-if="currentTab === 'Personal Info'"
          :cannot-edit="employeeBasic.employmentStatus === 'disengaged'"
        />
        <EmployeeCertificate
          v-if="currentTab === 'Education & Certification'"
          :cannot-edit="employeeBasic.employmentStatus === 'disengaged'"
        />
        <EmployeeDetails
          v-if="currentTab === 'Employment Details'"
          :cannot-edit="employeeBasic.employmentStatus === 'disengaged'"
        />
        <PayInfo
          v-if="currentTab === 'Pay Information'"
          :cannot-edit="employeeBasic.employmentStatus === 'disengaged'"
        />
        <EmployeeRole
          v-if="currentTab === 'Employee Role'"
          :cannot-edit="employeeBasic.employmentStatus === 'disengaged'"
        />
        <EmployeeInsights
          v-if="currentTab === 'Insights'"
          :employee-details="employeeBasic"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import PersonalInfo from "./personalInfo";
import EmployeeCertificate from "./employeeCertificate";
import EmployeeDetails from "./employmentDetails";
import EmployeeInsights from "./employeeInsights/index";
import PayInfo from "./payInformation/index";
import EmployeeRole from "./employeeRole";
import Sidebar from "./sideActions/sideBar";

export default {
  components: {
    PersonalInfo,
    EmployeeInsights,
    EmployeeCertificate,
    EmployeeDetails,
    Breadcrumb,
    Sidebar,
    PayInfo,
    EmployeeRole,
  },
  props: ["user"],
  data() {
    return {
      employeeDetails: null,
      breadcrumbs: [
        {
          disabled: false,
          text: "Employee Directory",
          href: "EmployeeDirectory",
          id: "Employee Directory",
        },
        {
          disabled: false,
          text: this.user || this.$route.query.user,
          id: "View Employee Profile",
        },
      ],
      tabs: [],
      basicTab: ["Personal Info", "Education & Certification", "Employment Details"],
      currentTab: this.$route.query.currentTab,
    };
  },
  computed: {
    ...mapState({
      employeeBasic: (state) => state.employee.allEmployeeDetails,
      loading: (state) => state.employee.loading,
    }),
  },
  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    async handleClick(currentTab) {
      if (currentTab !== "Employee Groups") {
        this.currentTab = currentTab;
      } else {
        try {
          await this.$handlePrivilege("groups", "viewGroups");
          this.currentTab = currentTab;
        } catch (error) {
          this.$toasted.error(
            "You do not have permission to view this page please contact your admin",
            { duration: 5000 }
          );
        }
      }
    },
    handleBreadcrumbClick(event) {
      this.$router.push({
        name: event,
      });
    },
  },

  created() {
      this.tabs = [
        "Personal Info",
        "Education & Certification",
        "Employment Details",
        "Pay Information",
        "Employee Role",
        "Insights",
      ];
  },
};
</script>
